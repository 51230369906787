
import DateUtil from 'Utils/dateUtil';

export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },

    {
        title: 'Name',
        key: 'NAME',
        sortable: true,
        minWidth: 80,
    },

	{
        title: 'Claim Name',
        key: 'ClaimName',
		sortable: true,
        minWidth: 80,
    },

    
    {
        title: 'Notes',
        key: 'Notes',
        minWidth: 80,
    },
    {
        title: 'Claim Date',
        key: 'ClaimDate',
        minWidth: 80,

    },
    {
        title: 'Amount',
        // key: 'amount',
        minWidth: 80,
        render: (h, params) => {
            return h('span',params.row.amount?parseFloat( params.row.amount).toFixed(2):'');
        },
    },
    {
        title: 'Status',
        key: 'STATUS',
        minWidth: 80,
    },
 
]
